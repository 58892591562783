import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useFeatureFlags } from '@feature-flags/use-feature-flags';
import { useAtomValue } from 'jotai';
import { onboardingStatusAtom } from '../../../jotai/storefront/book-now';

export const useStorefrontBookNow = (): boolean => {
	const featureFlags = useFeatureFlags();
	const vendorStatus = useAtomValue(onboardingStatusAtom);

	return (
		Boolean(featureFlags[FEATURE_FLAGS.STOREFRONT_BOOK_NOW]) &&
		vendorStatus === 'ONBOARDED'
	);
};
