import { isUIReadabilityEnhancementsSelector } from '@redux/experiments/selectors/ui-readability-enhancements';
import { useAppSelector } from '@redux/hooks';
import { useStorefrontBookNow } from './useStorefrontBookNow';

export const useUiReadabilityEnhancements = (): boolean => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const isBookNow = useStorefrontBookNow();

	const uiReadabilityEnhancements = useAppSelector(
		isUIReadabilityEnhancementsSelector,
	);

	const isPaid = vendor?.isPaid ?? false;
	return isPaid && uiReadabilityEnhancements && !isBookNow;
};
